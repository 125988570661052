/**
 * Created by rudiboutinaud on 20/04/15.
 */

$(document).ready(function(){

    //slick carousel for partners on home page
    $('.autoplay').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    //slick carousel for partners on side menu
    $('.autoplay-small').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3500,
        arrows: false
    });

    $('.confirm').on('click', function(){
        if(confirm('Are you sure?')) {
            return true;
        } else {
            return false;
        }
    });

    /**
     * search-block custom select boxes
     */
    $('ul.dropdown-menu > li > a').click(function(){
        var value = $(this).html();
        var element = $(this).parent().parent().siblings().first();
        var field = element.data('field');
        element.html(value);
        $("[name='"+field+"']").val(value);
        //$('ul.dropdown-menu').css('display', 'none');
        //return false;
    });

    //validate form date before sending
    $('.validate').validate({
        //ignore: [],
        //highlight: function (element) {
        //    $(element).closest('.form-group').addClass('has-error');
        //},
        //unhighlight: function (element) {
        //    $(element).closest('.form-group').removeClass('has-error');
        //},
        //errorElement: 'span',
        //errorClass: 'help-block',
        //errorPlacement: function (error, element) {
        //    if (element.parent('.input-group').length) {
        //        error.insertAfter(element.parent());
        //    } else {
        //        error.insertAfter(element);
        //    }
        //}
    });

    jQuery.validator.addClassRules({
        price: {
            required: true,
            digits: true
        }
    });

});